import React from 'react'
import ReactHtmlParser from 'react-html-parser'

import { Link, graphql } from 'gatsby'

import Layout from '../parts/layout' 
import Alternatives from "../parts/alternatives"


const UseCaseTemplate = ({ data }) => {
  const alternatives = data.strapiUseCase.alternatives.map(alternative => {
    return data.allStrapiAlternative.nodes.find(node => node.title === alternative.title)
  })

  return (
    <Layout>
      <section>
        <div className="row">
          <h1>{data.strapiUseCase.title}</h1>
          <div className="content">{ ReactHtmlParser(data.strapiUseCase.description) }</div>
          { data.strapiUseCase.alternatives.length !== 0 && <>
            <h3>Related alternatives</h3>
            <Alternatives alternatives={ alternatives } />
          </> }
          <Link to="/use-cases/" className="back">&laquo; all use cases</Link>
        </div>
      </section>
    </Layout>
  )
}

export default UseCaseTemplate

export const query = graphql`query UseCaseTemplate($id: String!) {
  strapiUseCase(id: {eq: $id}) {
    id
    title
    description
    alternatives {
      title
    }
  }
  allStrapiAlternative {
    nodes {
      id
      title
      description
      logo {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 200, placeholder: NONE, layout: CONSTRAINED)
          }
        }
      }
      domains {
        slug
        title
      }
      layers {
        slug
        title
      }
    }
  }
}
`