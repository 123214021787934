import React from "react"
import ReactHtmlParser from 'react-html-parser'

import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image";

const Alternatives = ({ alternatives }) => {

  return (
    <div className="alternatives">
      { alternatives.map(alternative => (
        <div key={alternative.id} className="alternative box">
          <div className="logo">
            { alternative.logo && <a href={alternative.link} target="_blank" rel="noopener noreferrer">
              <GatsbyImage
                image={alternative.logo.localFile.childImageSharp.gatsbyImageData}
                alt={alternative.logo.alternativeText}
                objectFit="contain" />
            </a> }
          </div>
          <div className="text">
          <h2>{ alternative.title }</h2>
          <a href={ alternative.link } target="_blank" rel="noopener noreferrer" className="url">{ alternative.link }</a>
          { ReactHtmlParser(alternative.description) }
          { (alternative.domains || []).length > 0 &&
            <p className="meta">
              Domain{ alternative.domains.length > 1 ? "s" : "" }: { alternative.domains.map(domain => <Link key={domain.slug} to={`/domains/${domain.slug}`}>{ domain.title}</Link>) }
            </p>
          }
          { (alternative.layers || []).length > 0 && 
            <p className="meta">
              Layer{ alternative.layers.length > 1 ? "s" : "" }: { alternative.layers.map(layer => <Link key={layer.slug} to={`/layers/#${layer.slug}`} onClick={ ()=>{window.scrollTo(0, 0);} }>{ layer.title }</Link>) }
            </p>
          }
          </div>
        </div>
      )) }
    </div>
  );
}

export default Alternatives